import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { type Dispatch, type SetStateAction } from "react"
import { IS_PRODUCTION } from "shared-utils"

import { FIREBASE_CONFIG } from "@/config/client"

const firebaseConfig = {
  apiKey: FIREBASE_CONFIG.apiKey,
  authDomain: FIREBASE_CONFIG.authDomain,
  projectId: FIREBASE_CONFIG.projectId,
  storageBucket: FIREBASE_CONFIG.storageBucket,
  messagingSenderId: FIREBASE_CONFIG.messagingSenderId,
  appId: FIREBASE_CONFIG.appId,
  measurementId: FIREBASE_CONFIG.measurementId,
}

const vapidKey = FIREBASE_CONFIG.vapidKey

export async function getOrRegisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      "/"
    )

    if (serviceWorker) return serviceWorker

    return window.navigator.serviceWorker.register(
      IS_PRODUCTION
        ? "/firebase-messaging-sw-prod.js"
        : "/firebase-messaging-sw.js",
      {
        scope: "/",
      }
    )
  }
  throw new Error("The browser doesn`t support service worker.")
}

export async function requestNotificationPermission(
  setDeviceToken: Dispatch<SetStateAction<string>>
) {
  if (!("Notification" in window)) return

  const permission = await Notification.requestPermission()
  if (permission !== "granted") return

  const app = initializeApp(firebaseConfig)
  const messaging = getMessaging(app)
  const serviceWorker = await getOrRegisterServiceWorker()
  const currentToken = await getToken(messaging, {
    vapidKey: vapidKey,
    serviceWorkerRegistration: serviceWorker,
  })

  setDeviceToken(currentToken)

  onMessage(messaging, (payload) => {
    if (!payload.notification) return

    const { title = "", body = "" } = payload.notification
    const notification = new Notification(title, { body })

    notification.onclick = (event) => {
      event.preventDefault()
      window.location.href = "/notification"
    }
  })
}
