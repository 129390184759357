import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { getUserInfo } from "shared-utils/authentication"

import { GET_SUBSCRIBER_HASH } from "./federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

const useSubscriberHash = () => {
  const [isEnable, setIsEnable] = useState(true)
  const session = getUserInfo()

  const { data } = useQuery({
    queryKey: ["subscriberHash"],
    queryFn: async () => {
      const result = await federatedGqlClient.request(GET_SUBSCRIBER_HASH)

      setIsEnable(false)
      return result
    },
    // retry if user is logged in
    retry: Boolean(session?.user?.id),
    enabled: Boolean(session?.user?.id) && isEnable,
  })

  const subscriberHash = data?.subscriberHash?.subscriberHash
  const subscriberId = data?.subscriberHash?.subscriberId

  return { subscriberId, subscriberHash }
}

export default useSubscriberHash
