"use client"

import { useMutation } from "@tanstack/react-query"
import { useState } from "react"

import { federatedGqlClient } from "@/utils/graphqlClient"
import { UPDATE_DEVICE_TOKEN } from "../federated/mutations"

const useFirebaseActions = () => {
  const [deviceToken, setDeviceToken] = useState<string>("")

  const { mutate } = useMutation({
    mutationKey: ["updateDeviceToken"],
    mutationFn: async (token: string) => {
      return await federatedGqlClient.request(UPDATE_DEVICE_TOKEN, {
        deviceToken: token,
      })
    },
  })

  return {
    deviceToken,
    setDeviceToken,
    mutate,
  }
}

export default useFirebaseActions
