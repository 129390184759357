import { DialogAuth } from "gtp-ui"
import { type Session } from "next-auth"
import { useSession } from "next-auth/react"

import { signIn } from "@/authentication/authService"
import { AUTH } from "@/config/client"

type TDialogProvider = {
  session?: Session | null
}

const DialogAuthComponent = ({ session: sessionServer }: TDialogProvider) => {
  const { data } = useSession()

  return (
    <DialogAuth
      errorSession={data?.error}
      baseUrl={AUTH.BASE_URL}
      signIn={signIn}
      shouldRefreshPage={
        sessionServer?.token?.accessToken !==
        sessionServer?.token?.previousAccessToken
      }
    />
  )
}

export default DialogAuthComponent
