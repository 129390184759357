import { graphql } from "@/federatedGql"

export const GET_SUBSCRIBER_HASH = graphql(`
  query getSubscriberHash {
    subscriberHash {
      subscriberHash
      subscriberId
    }
  }
`)
