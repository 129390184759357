import { useEffect, type PropsWithChildren } from "react"

import { requestNotificationPermission } from "./firebase"
import useFirebaseActions from "./hooks/useFirebaseActions"

const FirebaseProvider = (props: PropsWithChildren) => {
  const { deviceToken, setDeviceToken, mutate } = useFirebaseActions()

  useEffect(() => {
    requestNotificationPermission(setDeviceToken)

    if (deviceToken) {
      mutate(deviceToken)
    }
  }, [deviceToken, setDeviceToken, mutate])

  return <>{props.children}</>
}

export default FirebaseProvider
