import { type KeyboardEvent, type WheelEvent } from "react"

import { ACCOUNT_PPN_URL } from "@/config/client"

export const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
  if (
    event.key === "-" ||
    event.key === "." ||
    event.key === "ArrowUp" ||
    event.key === "ArrowDown"
  ) {
    event.preventDefault()
  }
}

export const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
  event.currentTarget.blur()
}

export const pdkiUrl =
  "https://pdki-indonesia.dgip.go.id/search?type=trademark&page=1&keyword="

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes"

  const units = ["bytes", "Kb", "Mb", "Gb", "Tb"]
  const dm = decimals < 0 ? 0 : decimals

  let convertedBytes = bytes
  let i = 0

  while (convertedBytes >= 1024 && i < units.length - 1) {
    convertedBytes /= 1024
    i++
  }

  const selectedUnit = units[i]

  if (typeof selectedUnit === "string") {
    return `${convertedBytes.toFixed(dm)} ${selectedUnit}`
  }

  return "0 Bytes"
}

export const formatSeconds = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0")

  return h + ":" + m + ":" + s
}

export const getAccountPpnUrl = (personaId?: string) => {
  return {
    setting: `${ACCOUNT_PPN_URL}/settings/account-profile?personaId=${personaId}`,
    esign: `${ACCOUNT_PPN_URL}/esign-setting`,
    shippingAddress: `${ACCOUNT_PPN_URL}/settings/shipping-address?personaId=${personaId}`,
    bankAccount: `${ACCOUNT_PPN_URL}/settings/bank-account?personaId=${personaId}`,
    kbli: `${ACCOUNT_PPN_URL}/settings/company-information?personaId=${personaId}#kbli`,
  }
}
