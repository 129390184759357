import { graphql } from "@/federatedGql/gql"

export const GET_UNREAD_CHAT_COUNT = graphql(`
  query unreadChatCount {
    unreadChatCount {
      ... on UnreadChatCountResp {
        count
      }
      ... on GenericError {
        reqId
        code
        message
      }
    }
  }
`)
