"use client"

import { type Session } from "next-auth"
import { SessionProvider } from "next-auth/react"
import { useEffect } from "react"
import { clearSession } from "shared-utils/authentication"

import DialogAuth from "./DialogAuth"

type TSessionProviderClient = {
  children: React.ReactNode
  session?: Session | null
}

export default function SessionProviderClient({
  children,
  session,
}: TSessionProviderClient) {
  clearSession()

  // Update session
  useEffect(() => {
    window.dataLayer?.push({
      user_id: session?.user?.id ?? "",
      persona_id: session?.user?.personaId ?? "",
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <SessionProvider>
      <DialogAuth session={session} />
      {children}
    </SessionProvider>
  )
}
