import { type Session } from "next-auth"
import {
  APP_TYPE,
  FEEDS_IDENTIFIER,
  NotificationProvider,
  usePersonaNotification,
} from "notifications"
import { useEffect, type PropsWithChildren } from "react"

import { NOVU_CONFIGURATION } from "@/config/client"
import useSubscriberHash from "@/hooks/notification/useSubscriberHash"

interface INotificationProviderProps extends PropsWithChildren {
  session: Session | null
}

const NotificationProviders = (props: INotificationProviderProps) => {
  const { subscriberHash, subscriberId } = useSubscriberHash()
  const { setUser } = usePersonaNotification()

  useEffect(() => {
    if (props.session) {
      setUser({
        institutionId: "",
        personaId: props.session?.user?.personaId || "",
        role: props.session?.user?.role || "",
        satker: "",
      })
    }
  }, [props.session])

  return (
    <NotificationProvider
      novuConfiguration={NOVU_CONFIGURATION}
      subscriberId={subscriberId}
      subscriberHash={subscriberHash}
      appType={APP_TYPE.seller}
      feeds={FEEDS_IDENTIFIER.govmart}
    >
      {props.children}
    </NotificationProvider>
  )
}

export default NotificationProviders
