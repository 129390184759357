import { graphql } from "@/federatedGql"

export const UPDATE_DEVICE_TOKEN = graphql(`
  mutation updateDeviceToken($deviceToken: [ID!]!) {
    updateDeviceToken(deviceTokens: $deviceToken) {
      credentials {
        deviceTokens
        providerId
      }
      userId
    }
  }
`)
