import { useQueryClient } from "@tanstack/react-query"
import { usePathname } from "next/navigation"
import { useEffect } from "react"

import { type GetListProductQuery } from "@/federatedGql/graphql"
import { useProductStatusStore } from "@/store/productStatusStore"
import { type GenericExtractor } from "@/types/type"

const ProductListUpdater = () => {
  const { listOfParams, clearListOfParams } = useProductStatusStore()
  const queryClient = useQueryClient()
  const pathname = usePathname()

  useEffect(() => {
    if (pathname !== "/products" && !!listOfParams.length) {
      Promise.all(
        listOfParams.map((dtParam) => {
          return queryClient.setQueryData<GetListProductQuery>(
            ["getListProduct", dtParam.pagination],
            (prevData) => {
              const listProduct = prevData?.listProduct as GenericExtractor<
                GetListProductQuery["listProduct"]
              >
              return {
                listProduct: {
                  ...listProduct,
                  items: listProduct?.items?.map((product) => {
                    const matchedParam = dtParam.params.find(
                      (itemParam) => itemParam.id === product.id
                    )
                    if (matchedParam) {
                      return { ...product, isActive: matchedParam.isActive }
                    }
                    return product
                  }),
                },
              }
            }
          )
        })
      ).then(() => clearListOfParams())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  return null
}

export default ProductListUpdater
