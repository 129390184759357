import { type PaginationState } from "@tanstack/react-table"
import equal from "fast-deep-equal"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

type TParam = {
  id: string
  isActive: boolean
}
type TParamUpdateProduct = {
  pagination: PaginationState
  params: Array<TParam>
}

type TUseProductStatusStore = {
  listOfParams: TParamUpdateProduct[]
  addListOfParams: (param: TParamUpdateProduct) => void
  clearListOfParams: () => void
}

export const useProductStatusStore = create<TUseProductStatusStore>()(
  devtools((set, get) => ({
    listOfParams: [],
    addListOfParams: (param) => {
      const prevList = get().listOfParams
      if (!prevList.length) {
        set(() => ({
          listOfParams: [param],
        }))
        return
      }
      if (
        !prevList.some((dtParam) => equal(dtParam.pagination, param.pagination))
      ) {
        set(() => ({
          listOfParams: [...prevList, param],
        }))
        return
      }

      set(() => ({
        listOfParams: prevList.reduce(
          (tempList: TParamUpdateProduct[], currParam) => {
            if (!equal(currParam.pagination, param.pagination)) {
              tempList.push(currParam)
              return tempList
            }
            const newParams: TParam[] = [
              ...currParam.params,
              ...param.params,
            ].reduce((tempParams: TParam[], currItemParam) => {
              const idx = tempParams.findIndex(
                (itemParam) => itemParam.id === currItemParam.id
              )
              if (idx > -1) {
                tempParams.splice(idx, 1)
              }
              tempParams.push(currItemParam)
              return tempParams
            }, [])
            tempList.push({ ...currParam, params: newParams })
            return tempList
          },
          []
        ),
      }))
    },
    clearListOfParams: () =>
      set(() => ({
        listOfParams: [],
      })),
  }))
)
